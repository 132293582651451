import { motion, useAnimation } from "framer-motion";
import Grid from "../../../../components/grid/Grid";
import Subheading from "../../../../components/subheading/Subheading";
import methodOne from "../../../../img/diagram-step-icon-1.png";
import methodTwo from "../../../../img/diagram-step-icon-2.png";
import methodThree from "../../../../img/diagram-step-icon-3.png";
import methodFour from "../../../../img/diagram-step-icon-4.png";
import "./Methodology.css";

const Methodology = () => {
  const controls = useAnimation();

  return (
    <div className="methodology">
      <Grid resize="4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="methodology-wrapper"
        >
          <Subheading
            align="Center"
            color="Light"
            margin="Bottom"
            size="Max"
            variant="Primary"
          >
            METODOLOGÍA
          </Subheading>

          <div className="methodologies-box">
            <div className="methodology-item">
              <img
                src={methodOne}
                alt="Evaluación"
                className="methodology-image"
              />

              <Subheading
                align="Center"
                color="Light"
                margin="Bottom"
                size="Min"
                variant="Secondary"
              >
                EVALUACIÓN
              </Subheading>
            </div>

            <div className="methodology-item">
              <img
                src={methodTwo}
                alt="Plan de Mejora y Desarrollo"
                className="methodology-image"
              />

              <Subheading
                align="Center"
                color="Light"
                margin="Bottom"
                size="Min"
                variant="Secondary"
              >
                PLAN DE MEJORA Y DESARROLLO
              </Subheading>
            </div>

            <div className="methodology-item">
              <img
                src={methodThree}
                alt="Implementación"
                className="methodology-image"
              />

              <Subheading
                align="Center"
                color="Light"
                margin="Bottom"
                size="Min"
                variant="Secondary"
              >
                IMPLEMENTACIÓN
              </Subheading>
            </div>

            <div className="methodology-item">
              <img
                src={methodFour}
                alt="Mejora Continua"
                className="methodology-image"
              />

              <Subheading
                align="Center"
                color="Light"
                margin="Bottom"
                size="Min"
                variant="Secondary"
              >
                MEJORA CONTÍNUA
              </Subheading>
            </div>
          </div>
        </motion.div>
      </Grid>
    </div>
  );
};

export default Methodology;
