import { motion, useAnimation } from "framer-motion";
import Grid from "../../../../components/grid/Grid";
import Subheading from "../../../../components/subheading/Subheading";
import Text from "../../../../components/text/Text";
import "./Proposal.css";

const Proposal = () => {
  const controls = useAnimation();

  return (
    <div className="proposal">
      <Grid resize="6">
        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="proposal-items-wrapper"
        >
          <div className="proposal-item-box">
            <p className="proposal-item-number">1</p>

            <Subheading
              align="Center"
              color="Dark"
              margin="Top"
              size="Max"
              variant="Secondary"
            >
              MÁS LIMPIO
            </Subheading>

            <Text
              align="Center"
              color="Dark"
              margin="Top"
              size="Normal"
              weight="Regular"
            >
              Garantizamos un alto estándar en la limpieza y desinfección de tu
              organización con el mejor equipo de trabajo a tu disposición.
            </Text>
          </div>

          <div className="proposal-item-box">
            <p className="proposal-item-number">2</p>

            <Subheading
              align="Center"
              color="Dark"
              margin="Top"
              size="Max"
              variant="Secondary"
            >
              MÁS RÁPIDO
            </Subheading>

            <Text
              align="Center"
              color="Dark"
              margin="Top"
              size="Normal"
              weight="Regular"
            >
              Preocupados por la salud, bienestar y calidad de vida, aseguramos
              espacios saludables en un tiempo adecuado, buscando la mejora
              continua para el desarrollo de una cultura de bienestar.
            </Text>
          </div>

          <div className="proposal-item-box">
            <p className="proposal-item-number">3</p>

            <Subheading
              align="Center"
              color="Dark"
              margin="Top"
              size="Max"
              variant="Secondary"
            >
              MÁS SÓLIDO
            </Subheading>

            <Text
              align="Center"
              color="Dark"
              margin="Top"
              size="Normal"
              weight="Regular"
            >
              Contribuímos a la continuidad de las empresas, ahorrando costos, a
              través de una mejora en la eficiencia operativa y la
              productividad.
            </Text>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="proposal-text-wrapper"
        >
          <Text
            align="Center"
            color="Dark"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Solido 360º ofrece soluciones integrales en el diseño e
            implementación de metodologías y protocolos que garantizan un nivel
            de bajo riesgo sanitario.
          </Text>
        </motion.div>
      </Grid>
    </div>
  );
};

export default Proposal;
