import { motion, useAnimation } from "framer-motion";
import HubSpotForm from "react-hubspot-form";
import Grid from "../../../components/grid/Grid";
import Subheading from "../../../components/subheading/Subheading";
import Text from "../../../components/text/Text";
import "./Contact.css";

const Contact = (props) => {
  const controls = useAnimation();

  const handleSubmit = () => {
    alert("submit");
  };

  return (
    <div ref={props.contactRef} className="contact">
      <Grid resize="9">
        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="contact-data-wrapper"
        >
          <Subheading align="Left" color="Dark" size="Mid" variant="Secondary">
            SOLICITA UNA PROPUESTA
          </Subheading>

          <Text
            align="Left"
            color="Dark"
            margin="Top"
            size="Normal"
            weight="Regular"
          >
            Comunícate con nosotros y con gusto te contactaremos para ofrecerte
            la mejor opción en soluciones de limpieza.
          </Text>

          <span className="contact-badge">
            <Text align="Left" color="Blue" size="Normal" weight="Semi">
              CORREO:
            </Text>

            <Text align="Left" color="Dark" size="Normal" weight="Regular">
              comercial@solido360.com
            </Text>
          </span>

          <span className="contact-badge">
            <Text align="Left" color="Blue" size="Normal" weight="Semi">
              TELÉFONOS:
            </Text>

            <Text align="Left" color="Dark" size="Normal" weight="Regular">
              55 2654 4811 / 55 4911 0249
            </Text>
          </span>

          <span className="contact-badge">
            <Text align="Left" color="Blue" size="Normal" weight="Semi">
              DIRECCIÓN:
            </Text>

            <Text align="Left" color="Dark" size="Normal" weight="Regular">
              Avenida Presidente Masaryk 1401 oficina 65, Polanco V Sección,
              Miguel Hidalgo CDMX
            </Text>
          </span>

          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d559.306512249024!2d-99.1855121700726!3d19.431808271358705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8ab468fffff%3A0xb4eb2816f3729b9c!2sAv.%20Pdte.%20Masaryk%20101%20-1401%2C%20Polanco%2C%20Polanco%20V%20Secc%2C%20Miguel%20Hidalgo%2C%2011560%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1702172016566!5m2!1ses!2smx"
            allowfullscreen=""
            loading="lazy"
            title="Nuestra ubicación"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </motion.div>

        <motion.form
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          onSubmit={handleSubmit}
          className="contact-form"
        >
          <Subheading align="Center" color="Light" size="Mid" variant="Primary">
            AGENDA UNA LLAMADA
          </Subheading>

          <Text
            align="Center"
            color="Light"
            margin="Top"
            size="Normal"
            weight="Regular"
          >
            Registra tus datos para que un especialista se ponga en contacto
            contigo y podamos resolver tus dudas.
          </Text>

          <br />

          <div className="form-wrapper">
            <HubSpotForm
              portalId="44728469"
              formId="d0f62014-e4b2-4c48-8496-71bda2355429"
              loading={<div>Loading...</div>}
            />
          </div>

          {/*<input className="contact-input" placeholder="Nombre" type="text" />

          <input className="contact-input" placeholder="Email" type="email" />

          <input className="contact-input" placeholder="Asunto" type="text" />

          <textarea
            className="contact-input contact-textarea"
            placeholder="Mensaje"
            name=""
            id=""
            cols="30"
            rows="7"
          ></textarea>

          <input
            className="contact-button"
            type="submit"
            value="ENVIAR MENSAJE"
  />*/}
        </motion.form>
      </Grid>
    </div>
  );
};

export default Contact;
