import { useState } from "react";
import Grid from "../../components/grid/Grid";
import HeaderButton from "../../components/headerButton/HeaderButton";
import ColorButton from "../../components/colorButton/ColorButton";
import logo from "../../img/logo.png";
import menu from "../../img/menu.png";
import "./Header.css";

const Header = (props) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <div className="header">
      <Grid resize="1">
        <div className="header-logo-wrapper">
          <img src={logo} alt="Sólido 360" className="header-logo" />
        </div>

        <nav className="desktop-header-nav">
          <HeaderButton onClick={props.homeOnClick}>Inicio</HeaderButton>

          <HeaderButton onClick={props.servicesOnClick}>Servicios</HeaderButton>

          <HeaderButton onClick={props.aboutUsOnClick}>Nosotros</HeaderButton>

          <HeaderButton onClick={props.contactOnClick}>Contacto</HeaderButton>
        </nav>

        <nav className="desktop-header-button">
          <a
            className="header-redirection-link"
            target="blank"
            href="https://www.facebook.com/profile.php?id=100065009452135"
          >
            <ColorButton color="Blue">ÚNETE AL EQUIPO</ColorButton>
          </a>
        </nav>

        <div className="mobile-header-button-wrapper">
          <button
            onClick={() => setMobileMenu(!mobileMenu)}
            className="mobile-header-menu-button"
          >
            <img src={menu} alt="menú" className="mobile-header-menu-icon" />
          </button>
        </div>
      </Grid>

      <nav className={mobileMenu ? "mobile-menu" : "mobile-menu-hidden"}>
        <HeaderButton onClick={props.homeOnClick}>Inicio</HeaderButton>

        <HeaderButton onClick={props.servicesOnClick}>Servicios</HeaderButton>

        <HeaderButton onClick={props.aboutUsOnClick}>Nosotros</HeaderButton>

        <HeaderButton onClick={props.contactOnClick}>Contacto</HeaderButton>
      </nav>
    </div>
  );
};

export default Header;
