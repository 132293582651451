import "./Heading.css";

const Heading = (props) => {
  return (
    <h1
      className={`heading heading${props.align} heading${props.color} heading${props.margin} heading${props.size}`}
    >
      {props.children}
    </h1>
  );
};

export default Heading;
