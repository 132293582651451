import bg1 from "../../../../../img/landing-bg1.jpg";
import bg2 from "../../../../../img/landing-bg2.jpg";
import bg3 from "../../../../../img/landing-bg3.jpg";
import bg4 from "../../../../../img/landing-bg4.jpg";
import bg5 from "../../../../../img/landing-bg5.jpg";
import bg6 from "../../../../../img/landing-bg6.jpg";
import "./LandingBackground.css";

export const LandingBackgroundOne = () => {
  return (
    <div className="landing-banner-ref">
      <div className="landing-banner landing-banner-1">
        <span className="landing-banner-img-wrapper landing-banner-img-wrapper-small">
          <img className="landing-banner-img" src={bg1} alt="Imagen de fondo" />
        </span>

        <div className="landing-banner-img-divider"></div>

        <span className="landing-banner-img-wrapper landing-banner-img-wrapper-big">
          <img className="landing-banner-img" src={bg2} alt="Imagen de fondo" />
        </span>

        <div className="landing-banner-img-divider"></div>

        <span className="landing-banner-img-wrapper landing-banner-img-wrapper-big">
          <img className="landing-banner-img" src={bg3} alt="Imagen de fondo" />
        </span>
      </div>
    </div>
  );
};

export const LandingBackgroundTwo = () => {
  return (
    <div className="landing-banner-ref">
      <div className="landing-banner landing-banner-2">
        <span className="landing-banner-img-wrapper landing-banner-img-wrapper-big">
          <img className="landing-banner-img" src={bg4} alt="Imagen de fondo" />
        </span>

        <div className="landing-banner-img-divider"></div>

        <span className="landing-banner-img-wrapper landing-banner-img-wrapper-big">
          <img className="landing-banner-img" src={bg5} alt="Imagen de fondo" />
        </span>

        <div className="landing-banner-img-divider"></div>

        <span className="landing-banner-img-wrapper landing-banner-img-wrapper-small">
          <img className="landing-banner-img" src={bg6} alt="Imagen de fondo" />
        </span>
      </div>
    </div>
  );
};
