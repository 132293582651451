import Layout from "./layout/Layout";
import "./App.css";

// Change the Home call to action buttons so they redirect
// Unete al equipo to fb
// Solicita informacion to Contact
// Activate whatsapp button with the provided number
// Add certifications banner

const App = () => {
  return (
    <div className="App">
      <Layout />
    </div>
  );
};

export default App;
