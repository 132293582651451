import "./HeaderButton.css";

const HeaderButton = (props) => {
  return (
    <button onClick={props.onClick} className="headerButton">
      {props.children}
    </button>
  );
};

export default HeaderButton;
