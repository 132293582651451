import "./Subheading.css";

const Subheading = (props) => {
  return (
    <h2
      className={`subheading${props.align} subheading${props.color} subheading${props.margin} subheading${props.size} subheading${props.variant}`}
    >
      {props.children}
    </h2>
  );
};

export default Subheading;
