import Grid from "../../../../components/grid/Grid";
import {
  LandingBackgroundOne,
  LandingBackgroundTwo,
} from "./landingBackground/LandingBackground";
import LandingIntro from "./landingIntro/LandingIntro";
import "./Landing.css";

const Landing = (props) => {
  return (
    <div className="landing">
      <div className="landing-wrapper">
        <Grid resize="8Landing">
          <div className="landing-bg-banner-box landing-bg-banner-box-1">
            <LandingBackgroundOne />
          </div>
          <div className="landing-bg-banner-box landing-bg-banner-box-2">
            <LandingBackgroundTwo />
          </div>

          <section className="landing-transparency"></section>

          <div className="landing-intro-wrapper">
            <LandingIntro onContactClick={props.onContactClick} />
          </div>
        </Grid>
      </div>

      <Grid resize="8"></Grid>
    </div>
  );
};

export default Landing;
