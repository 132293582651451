import { motion, useAnimation } from "framer-motion";
import Grid from "../../../../components/grid/Grid";
import Text from "../../../../components/text/Text";
import star from "../../../../img/star.png";
import "./Testimonials.css";

const Testimonials = () => {
  const controls = useAnimation();

  return (
    <div className="testimonials">
      <Grid resize="4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="testimonials-wrapper"
        >
          <Text align="Center" color="Light" size="Highlight" weight="Semi">
            Testimonios de clientes que han contratado nuestros servicios:
          </Text>

          <br />

          <div className="testimonials-box">
            <div className="testimonial">
              <Text
                align="Center"
                color="Light"
                margin="Bottom"
                size="Normal"
                weight="Regular"
              >
                "Más que un proveedor un socio estratégico en estos tiempos de
                incertidumbre."
              </Text>

              <div className="testimonial-stars-wrapper">
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
              </div>

              <Text
                align="Center"
                color="Aqua"
                margin="Top"
                size="Highlight"
                weight="Semi"
              >
                Edith Paz
              </Text>

              <Text
                align="Center"
                color="Light"
                margin="Top"
                size="Normal"
                weight="Regular"
              >
                Directora General de operación y Logistica
              </Text>
            </div>

            <div className="testimonial">
              <Text
                align="Center"
                color="Light"
                margin="Bottom"
                size="Normal"
                weight="Regular"
              >
                "Me encanta su forma de trabajar, siempre al pendiente de las
                necesidades del cliente."
              </Text>

              <div className="testimonial-stars-wrapper">
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
              </div>

              <Text
                align="Center"
                color="Aqua"
                margin="Top"
                size="Highlight"
                weight="Semi"
              >
                Manuel Reyes
              </Text>

              <Text
                align="Center"
                color="Light"
                margin="Top"
                size="Normal"
                weight="Regular"
              >
                Director de Remani
              </Text>
            </div>

            <div className="testimonial">
              <Text
                align="Center"
                color="Light"
                margin="Bottom"
                size="Normal"
                weight="Regular"
              >
                "Excelente servicio, muy puntuales y con gran cultura del
                servicio en todo momento."
              </Text>

              <div className="testimonial-stars-wrapper">
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
                <img src={star} alt="Estrella" className="testimonial-star" />
              </div>

              <Text
                align="Center"
                color="Aqua"
                margin="Top"
                size="Highlight"
                weight="Semi"
              >
                Mariana Ramirez
              </Text>

              <Text
                align="Center"
                color="Light"
                margin="Top"
                size="Normal"
                weight="Regular"
              >
                Director de Recursos Humanos Santul
              </Text>
            </div>
          </div>
        </motion.div>
      </Grid>
    </div>
  );
};

export default Testimonials;
