import "./Text.css";

const Text = (props) => {
  return (
    <p
      className={`text text${props.align} text${props.color} text${props.margin} text${props.size} text${props.weight}`}
    >
      {props.children}
    </p>
  );
};

export default Text;
