import { motion, useAnimation } from "framer-motion";
import Grid from "../../../../components/grid/Grid";
import Subheading from "../../../../components/subheading/Subheading";
import Text from "../../../../components/text/Text";
import logo from "../../../../img/logo.png";
import diagram from "../../../../img/about-us-diagram.png";
import "./AboutUsIntro.css";

const AboutUsIntro = () => {
  const controls = useAnimation();

  return (
    <div className="about-us-intro">
      <Grid resize="4">
        <div className="about-us-presentation-box" />

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="about-us-presentation"
        >
          <img
            src={logo}
            alt="Sólido 360"
            className="about-us-presentation-logo"
          />

          <Subheading
            align="Left"
            color="Light"
            margin="Top"
            size="Max"
            variant="Secondary"
          >
            SOLUCIONES DE LIMPIEZA PARA ORGANIZACIONES
          </Subheading>

          <Text
            align="Left"
            color="Light"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Somos un grupo de expertos en el diseño e implementación de
            metodologías que garantizan un bajo nivel de riesgo sanitario, a
            través de un Modelo de Salud Solido 360º.
          </Text>
        </motion.div>
      </Grid>

      <Grid resize={window.innerWidth < 1000 ? "5" : "7"}>
        <div className="about-us-culture-wrapper" />

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="about-us-culture"
        >
          <Subheading
            align="Left"
            color="Blue"
            margin="Bottom"
            size="Max"
            variant="Secondary"
          >
            CULTURA SOLIDO 360º
          </Subheading>

          <hr className="about-us-cuture-divider" />

          <Text
            align="Left"
            color="Dark"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Contribuímos en la prevención de la salud y la mejora de la calidad
            de vida de las personas, manteniendo ambientes saludables que
            favorezcan el bienestar y la economía de las organizaciones, bajo un
            enfoque de mejora continua.
          </Text>

          <br />

          <Text
            align="Left"
            color="Dark"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Creamos una cultura 360 de bienestar, calidad y servicio en un
            entorno de 360º.
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="about-us-diagram-wrapper"
        >
          <Subheading
            align="Center"
            color="Dark"
            margin="Bottom"
            size="Mid"
            variant="Secondary"
          >
            NUESTRO MODELO 360
          </Subheading>

          <img src={diagram} alt="Modelo 360" className="about-us-diagram" />
        </motion.div>
      </Grid>
    </div>
  );
};

export default AboutUsIntro;
