import "./ColorButton.css";

const ColorButton = (props) => {
  return (
    <button
      className={`colorButton colorButton${props.color}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default ColorButton;
