import { useRef } from "react";
import Header from "./header/Header";
import Content from "./content/Content";
import Footer from "./footer/Footer";
// import WhatsappButton from "./whatsappButton/WhatsappButton";
import "./Layout.css";

const Layout = () => {
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactRef = useRef(null);

  const homeOnClick = () => {
    homeRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const servicesOnClick = () => {
    servicesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const aboutUsOnClick = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const contactOnClick = () => {
    contactRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Header
        homeOnClick={homeOnClick}
        servicesOnClick={servicesOnClick}
        aboutUsOnClick={aboutUsOnClick}
        contactOnClick={contactOnClick}
      />
      <Content
        homeRef={homeRef}
        servicesRef={servicesRef}
        aboutUsRef={aboutUsRef}
        contactRef={contactRef}
        onContactClick={contactOnClick}
      />
      <Footer />

      {/* <WhatsappButton /> */}
    </div>
  );
};

export default Layout;
