import { motion, useAnimation } from "framer-motion";
import Grid from "../../../../components/grid/Grid";
import Subheading from "../../../../components/subheading/Subheading";
import Text from "../../../../components/text/Text";
import "./Characteristics.css";

const Characteristics = () => {
  const controls = useAnimation();

  return (
    <div className="characteristics">
      <Grid resize="5">
        <div className="characteristics-heading-wrapper">
          <Subheading align="Center" color="Light" size="Max" variant="Primary">
            CARACTERÍSTICAS
          </Subheading>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.3, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="characteristic-box characteristic-box-1"
        >
          <p className="characteristic-number">1</p>

          <Text
            align="Center"
            color="Light"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Enfoque 100% preventivo, empresarial y personal
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.3, delay: 0.6 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="characteristic-box characteristic-box-2"
        >
          <p className="characteristic-number">2</p>

          <Text
            align="Center"
            color="Light"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Favorece y educa sobre una cultura de prevención
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.3, delay: 0.9 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="characteristic-box characteristic-box-3"
        >
          <p className="characteristic-number">3</p>

          <Text
            align="Center"
            color="Light"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Garantiza un nivel bajo de exposición al riesgo
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.3, delay: 1.2 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="characteristic-box characteristic-box-4"
        >
          <p className="characteristic-number">4</p>

          <Text
            align="Center"
            color="Light"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Alineado a Normas ISO internacionales
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.3, delay: 1.5 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="characteristic-box characteristic-box-5"
        >
          <p className="characteristic-number">5</p>

          <Text
            align="Center"
            color="Light"
            margin="Top"
            size="Highlight"
            weight="Regular"
          >
            Cuenta con el respaldo de ONG certificadores en metodologías de éste
            tipo
          </Text>
        </motion.div>
      </Grid>
    </div>
  );
};

export default Characteristics;
