import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Heading from "../../../../../components/heading/Heading";
import ColorButton from "../../../../../components/colorButton/ColorButton";
import "./LandingIntro.css";

const useParallax = (value, distance) => {
  return useTransform(value, [0, 1], [-distance, distance]);
};

const LandingIntro = (props) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, 250);

  return (
    <div ref={ref} className="landing-box">
      <motion.div style={{ y }} className="landing-intro">
        <Heading align="Left" color="Light" margin="Bottom" size="Max">
          ESPECIALISTAS EN LIMPIEZA PARA ESPACIOS RESIDENCIALES, INDUSTRIALES Y
          COMERCIALES
        </Heading>

        <br />
        <br />

        <nav className="landing-navbar">
          <ColorButton onClick={props.onContactClick} color="Red">
            SOLICITA INFORMACIÓN
          </ColorButton>
          <a
            className="header-redirection-link"
            target="blank"
            href="https://www.facebook.com/profile.php?id=100065009452135"
          >
            <ColorButton color="Blue">ÚNETE AL EQUIPO</ColorButton>
          </a>
        </nav>
      </motion.div>
    </div>
  );
};

export default LandingIntro;
