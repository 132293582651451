import { motion, useAnimation } from "framer-motion";
import Grid from "../../../../components/grid/Grid";
import Subheading from "../../../../components/subheading/Subheading";
import Text from "../../../../components/text/Text";
import "./Mission.css";

const Mission = () => {
  const controls = useAnimation();

  return (
    <div className="mission">
      <Grid resize="3">
        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.3 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="mission-box mission-box-1"
        >
          <Subheading
            align="Center"
            color="Light"
            size="Mid"
            variant="Secondary"
          >
            NUESTRA MISIÓN
          </Subheading>

          <hr className="mission-divider" />

          <Text align="Center" color="Light" size="Normal" weight="Regular">
            Contribuir en la prevención de la salud y de la calidad de vida de
            las personas, manteniendo ambientes saludables y de bajo riesgo que
            impacten en el bienestar y en la economía de las organizaciones.
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 0.8 }}
          onViewportEnter={() => controls.start({ opacity: 1 })}
          onViewportLeave={() => controls.start({ opacity: 0 })}
          className="mission-box mission-box-2"
        >
          <Subheading
            align="Center"
            color="Light"
            size="Mid"
            variant="Secondary"
          >
            NUESTRO DIFERENCIADOR
          </Subheading>

          <hr className="mission-divider" />

          <Text align="Center" color="Light" size="Normal" weight="Regular">
            Contamos con un Modelo SOLIDO 360º. que contribuye a la continuidad
            de las empresas, al rompimiento de las cadenas de contagio y a la
            prevención del nivel de salud óptimo y de la calidad de vida de las
            personas.
          </Text>
        </motion.div>
      </Grid>
    </div>
  );
};

export default Mission;
