import AboutUsIntro from "./aboutUsIntro/AboutUsIntro";
import Methodology from "./methodology/Methodology";
import Mission from "./mission/Mission";
import OwnedCertificates from "./ownedCertificates/OwnedCertificates";
import Testimonials from "./testimonials/Testimonials";
import "./AboutUs.css";

const AboutUs = (props) => {
  return (
    <div ref={props.aboutUsRef}>
      <AboutUsIntro />
      <Methodology />
      <Mission />
      <OwnedCertificates />
      <Testimonials />
    </div>
  );
};

export default AboutUs;
