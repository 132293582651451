import { motion, useAnimation } from "framer-motion";
import Grid from "../../../../components/grid/Grid";
import Subheading from "../../../../components/subheading/Subheading";
import Text from "../../../../components/text/Text";
import "./ServiceBoxes.css";

const ServiceBoxes = () => {
  const controls = useAnimation();

  return (
    <div className="service-boxes">
      <Grid resize="8">
        <div className="service-boxes-wrapper">
          <motion.div
            initial={{ opacity: 0 }}
            animate={controls}
            transition={{ duration: 0.2, delay: 0 }}
            onViewportEnter={() => controls.start({ opacity: 1 })}
            onViewportLeave={() => controls.start({ opacity: 0 })}
            className="service-box service-box-1"
          >
            <div className="servide-box-title">
              <Subheading
                align="Left"
                color="Light"
                size="Mid"
                variant="Secondary"
              >
                Limpieza Integral
              </Subheading>
            </div>

            <div className="service-divider-wrapper">
              <hr className="service-divider" />
            </div>

            <div className="service-box-text">
              <Text
                align="Left"
                color="Light"
                size="Highlight"
                weight="Regular"
              >
                Profesionales especializados en limpieza integral de espacios.
                Personal altamente capacitado, confiable y experto en la
                limpieza.
              </Text>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={controls}
            transition={{ duration: 0.2, delay: 0.8 }}
            onViewportEnter={() => controls.start({ opacity: 1 })}
            onViewportLeave={() => controls.start({ opacity: 0 })}
            className="service-box service-box-2"
          >
            <div className="servide-box-title">
              <Subheading
                align="Left"
                color="Light"
                size="Mid"
                variant="Secondary"
              >
                Desinfección de Áreas
              </Subheading>
            </div>

            <div className="service-divider-wrapper">
              <hr className="service-divider" />
            </div>

            <div className="service-box-text">
              <Text
                align="Left"
                color="Light"
                size="Highlight"
                weight="Regular"
              >
                Desinfección y sanitización de inmuebles, espacios, superficies
                e inmuebles.
              </Text>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={controls}
            transition={{ duration: 0.2, delay: 0.4 }}
            onViewportEnter={() => controls.start({ opacity: 1 })}
            onViewportLeave={() => controls.start({ opacity: 0 })}
            className="service-box service-box-3"
          >
            <div className="servide-box-title">
              <Subheading
                align="Left"
                color="Light"
                size="Mid"
                variant="Secondary"
              >
                Mantenimiento, Pulido y Abrillantador de Pisos
              </Subheading>
            </div>

            <div className="service-divider-wrapper">
              <hr className="service-divider" />
            </div>

            <div className="service-box-text">
              <Text
                align="Left"
                color="Light"
                size="Highlight"
                weight="Regular"
              >
                Mantenimiento de áreas, pulido de pisos y abrillantador de
                pisos.
              </Text>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={controls}
            transition={{ duration: 0.2, delay: 0.6 }}
            onViewportEnter={() => controls.start({ opacity: 1 })}
            onViewportLeave={() => controls.start({ opacity: 0 })}
            className="service-box service-box-4"
          >
            <div className="servide-box-title">
              <Subheading
                align="Left"
                color="Light"
                size="Mid"
                variant="Secondary"
              >
                Jardinería
              </Subheading>
            </div>

            <div className="service-divider-wrapper">
              <hr className="service-divider" />
            </div>

            <div className="service-box-text">
              <Text
                align="Left"
                color="Light"
                size="Highlight"
                weight="Regular"
              >
                Cuidado, mantenimiento y control de jardines y áreas verdes.
              </Text>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={controls}
            transition={{ duration: 0.2, delay: 1 }}
            onViewportEnter={() => controls.start({ opacity: 1 })}
            onViewportLeave={() => controls.start({ opacity: 0 })}
            className="service-box service-box-5"
          >
            <div className="servide-box-title">
              <Subheading
                align="Left"
                color="Light"
                size="Mid"
                variant="Secondary"
              >
                Insumos de Limpieza
              </Subheading>
            </div>

            <div className="service-divider-wrapper">
              <hr className="service-divider" />
            </div>

            <div className="service-box-text">
              <Text
                align="Left"
                color="Light"
                size="Highlight"
                weight="Regular"
              >
                Venta de insumos y control de productos de saneamiento para
                instituciones y empresas.
              </Text>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={controls}
            transition={{ duration: 0.2, delay: 0.2 }}
            onViewportEnter={() => controls.start({ opacity: 1 })}
            onViewportLeave={() => controls.start({ opacity: 0 })}
            className="service-box service-box-6"
          >
            <div className="servide-box-title">
              <Subheading
                align="Left"
                color="Light"
                size="Mid"
                variant="Secondary"
              >
                Asesoría de Modelos de Salud de Bajo Riesgo
              </Subheading>
            </div>

            <div className="service-divider-wrapper">
              <hr className="service-divider" />
            </div>

            <div className="service-box-text">
              <Text
                align="Left"
                color="Light"
                size="Highlight"
                weight="Regular"
              >
                Protocolos y modelos de gestión de salud de bajo riesgo.
              </Text>
            </div>
          </motion.div>
        </div>
      </Grid>
    </div>
  );
};

export default ServiceBoxes;
