import Grid from "../../../../components/grid/Grid";
import Subheading from "../../../../components/subheading/Subheading";
import Text from "../../../../components/text/Text";
import "./OwnedCertificates.css";

const OwnedCertificates = () => {
  return (
    <div className="owned-certificates">
      <Grid resize="4">
        <div className="certificates-title">
          <Subheading
            align="Center"
            color="Dark"
            size="Max"
            variant="Secondary"
          >
            CERTIFICACIONES
          </Subheading>
        </div>
        <div className="certificate-wrapper certificate-wrapper-1">
          <Subheading align="Center" color="Dark" size="Mid" variant="Primary">
            Miembro activo afiliado a ISSA
          </Subheading>

          <hr className="certificate-divider" />

          <Text align="Center" color="Dark" size="Normal" weight="Regular">
            Asociación comercial líder para la industria de la limpieza a nivel
            mundial. Enfocada en procesos de limpieza administrada eficazmente,
            limpieza ecológica, sostenibilidad y protección de la salud humana.
          </Text>
        </div>

        <div className="certificate-wrapper certificate-wrapper-2">
          <Subheading align="Center" color="Dark" size="Mid" variant="Primary">
            Acreditación GBAC
          </Subheading>

          <hr className="certificate-divider" />

          <Text align="Center" color="Dark" size="Normal" weight="Regular">
            La única acreditación de prevención, respuesta y recuperación de
            brotes de la industria de la limpieza.
          </Text>
        </div>
      </Grid>
    </div>
  );
};

export default OwnedCertificates;
