import ServiceBoxes from "./serviceBoxes/ServiceBoxes";
import Characteristics from "./characteristics/Characteristics";
import "./Services.css";

const Services = (props) => {
  return (
    <div ref={props.servicesRef}>
      <ServiceBoxes />
      <Characteristics />
    </div>
  );
};

export default Services;
