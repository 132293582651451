import Grid from "../../components/grid/Grid";
import Text from "../../components/text/Text";
import facebook from "../../img/facebook.png";
import linkedin from "../../img/linkedin.png";
import instagram from "../../img/instagram.png";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <Grid resize="1">
        <div className="footer-rights-reserved">
          <Text align="Center" color="Light" size="Normal" weight="Regular">
            © {currentYear} Solido 360. Todos los derechos reservados
          </Text>
        </div>

        <nav className="footer-social-nav">
          <a
            href="https://www.facebook.com/profile.php?id=100065009452135"
            target="blank"
            className="footer-social-button"
          >
            <img src={facebook} alt="Facebook" className="footer-social-icon" />
          </a>

          <a
            href="https://www.linkedin.com/company/solido360/"
            target="blank"
            className="footer-social-button"
          >
            <img src={linkedin} alt="LinkedIn" className="footer-social-icon" />
          </a>

          <a
            href="https://www.instagram.com/solido360/"
            target="blank"
            className="footer-social-button"
          >
            <img
              src={instagram}
              alt="Instagram"
              className="footer-social-icon"
            />
          </a>
        </nav>

        <div className="footer-repse-registry">
          <Text align="Center" color="Aqua" size="Normal" weight="Regular">
            AVISO DE REGISTRO REPSE: AR8959/2021
          </Text>
        </div>
      </Grid>
    </div>
  );
};

export default Footer;
