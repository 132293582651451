import Landing from "./landing/Landing";
import Proposal from "./proposal/Proposal";
import Certifications from "./certifications/Certifications";
import "./Home.css";

const Home = (props) => {
  return (
    <div ref={props.homeRef}>
      <Landing onContactClick={props.onContactClick} />
      <Proposal />
      <Certifications />
    </div>
  );
};

export default Home;
