import "./Content.css";
import Home from "./home/Home";
import Services from "./services/Services";
import AboutUs from "./aboutUs/AboutUs";
import Contact from "./contact/Contact";

const Content = (props) => {
  return (
    <div>
      <Home onContactClick={props.onContactClick} homeRef={props.homeRef} />
      <Services servicesRef={props.servicesRef} />
      <AboutUs aboutUsRef={props.aboutUsRef} />
      <Contact contactRef={props.contactRef} />
    </div>
  );
};

export default Content;
