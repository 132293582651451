import Grid from "../../../../components/grid/Grid";
import esr from "../../../../img/esr.png";
import repse from "../../../../img/repse.png";
import stps from "../../../../img/stps.png";
import gbac from "../../../../img/gbac.png";
import issa from "../../../../img/issa.png";
import "./Certifications.css";

const Certifications = () => {
  return (
    <div className="certifications">
      <Grid resize="1">
        <div className="certification-logos">
          <img src={esr} alt="ESR" className="certification-logo" />
          <img src={repse} alt="REPSE" className="certification-logo" />
          <img src={stps} alt="STPS" className="certification-logo" />
          <img src={gbac} alt="GBAC" className="certification-logo" />
          <img src={issa} alt="ISSA" className="certification-logo" />
        </div>
      </Grid>
    </div>
  );
};

export default Certifications;
